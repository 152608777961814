<template>
    <div v-loading="loading" class="p-5 bg-white rounded-lg min-h-[500px]">
        <ul class="flex mb-2">
            <li v-for="(tab, index) in tabs" :key="index">
                <button
                    class="px-5 py-2 mr-2 text-sm border border-red-500 rounded-lg cursor-pointer"
                    :class="
                        currentTab === tab.value
                            ? 'bg-red-500 text-white'
                            : 'text-red-500'
                    "
                    @click="
                        changeTab({
                            value: tab.value,
                            component: tab.component,
                        })
                    "
                >
                    {{ tab.text }}
                </button>
            </li>
        </ul>
        <div
            v-for="(tab, index) in tabs"
            v-show="tab.value === currentTab"
            :key="index"
            class="w-full p-5 border border-gray-100 rounded-lg"
        >
            <ClientImageUpload
                v-if="currentTab === tab.value"
                :title="tab.title"
                :imageUrlKey="tab.imageUrlKey"
                :apiKey="tab.apiKey"
                :sizes="tab.sizes"
            />
        </div>
    </div>
</template>

<script >
import { ref, reactive, del, set, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import ClientImageUpload from "@/views/systemControl/clientImageSetting/components/ClientImageUpload.vue";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const loading = ref(false);
  const currentTab = ref(1);

  // 上傳圖片位置
  const tabs = ref([{
    text: "即刻快閃開單頁圖",
    title: "即刻快閃開單上方圖更換",
    imageUrlKey: "wdimageurl",
    apiKey: "wdimage",
    sizes: {
      width: [750],
      height: [350]
    },
    value: 1
  }, {
    text: "APP 首頁即刻快閃背景圖",
    title: "APP 首頁即刻快閃背景圖更換",
    imageUrlKey: "ahimageurl",
    apiKey: "ahimage",
    sizes: {
      width: [],
      height: []
    },
    value: 2
  }, {
    text: "APP 即刻快閃開單頁上方圖片",
    title: "APP 即刻快閃開單頁上方圖片更換",
    imageUrlKey: "adimageurl",
    apiKey: "adimage",
    sizes: {
      width: [],
      height: []
    },
    value: 3
  }]);
  function changeTab({
    value,
    component
  }) {
    currentTab.value = value;
    showComponent.value = component;
  }
  return {
    loading,
    currentTab,
    tabs,
    changeTab
  };
};
__sfc_main.components = Object.assign({
  ClientImageUpload
}, __sfc_main.components);
export default __sfc_main;
</script>
