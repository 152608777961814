<template>
    <div>
        <h2 class="mb-5 text-base font-medium text-center md:text-xl">
            {{ title }}
        </h2>
        <div
            v-if="!photoImage"
            class="h-[300px] flex items-center justify-center"
        >
            <img
                v-if="imageData"
                class="w-full mx-auto max-w-[300px]"
                :src="imageData"
                alt=""
            />
            <div v-else>未上傳圖片</div>
        </div>
        <Upload
            ref="imageUploadRefDom"
            :photoImage.sync="photoImage"
            :photoFile.sync="photoFile"
            :imageSizes="sizes"
        />
        <div v-if="photoFile" class="flex justify-center my-5">
            <button
                v-permission="['update']"
                class="orange-btn-800-lg text-md myDisabled"
                :disabled="!photoFile"
                @click="onSubmit()"
            >
                更新
            </button>
        </div>
    </div>
</template>

<script >
import { ref, reactive, del, set, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
// 圖片上傳組件
import Upload from "@/components/ImageUpload.vue";
const __sfc_main = {};
__sfc_main.props = {
  title: {
    type: String,
    default: ""
  },
  // 取得 api 圖片路徑 key
  imageUrlKey: {
    type: String,
    default: ""
  },
  // 更新圖片 key
  apiKey: {
    type: String,
    default: ""
  },
  sizes: {
    type: Object,
    default() {
      return {
        width: [350],
        height: [350]
      };
    }
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const {
    proxy
  } = getCurrentInstance();
  const props = __props;

  // 圖片base64
  const photoImage = ref(null);
  // 圖片檔案
  const photoFile = ref(null);
  // 已上傳圖片
  const imageData = ref("");
  const imageUploadRefDom = ref(null);
  async function getClientImg() {
    try {
      const {
        data
      } = await proxy.$api.GetClientImgApi();
      imageData.value = data.background[props.imageUrlKey];
    } catch (err) {
      console.log("GetClientImgApi =>", err);
      proxy.$message({
        type: "error",
        message: "取得圖片失敗"
      });
    }
  }
  async function onSubmit() {
    const result = new FormData();
    if (photoFile.value) {
      result.append(props.apiKey, photoFile.value);
    }
    try {
      await proxy.$api.UploadClientImgApi(result);
      photoImage.value = null;
      photoFile.value = null;
      imageUploadRefDom.value.reset();
      await getClientImg();
      proxy.$message({
        type: "success",
        message: "上傳成功"
      });
    } catch (err) {
      console.log("UploadClientImgApi =>", err);
    }
  }
  onMounted(async () => {
    await getClientImg();
  });
  return {
    photoImage,
    photoFile,
    imageData,
    imageUploadRefDom,
    onSubmit
  };
};
__sfc_main.components = Object.assign({
  Upload
}, __sfc_main.components);
export default __sfc_main;
</script>
